import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useTranslations } from 'gatsby-plugin-translate'
import { isMobile } from '@cms/utils'

import SectionTitle from '../../shared/SectionTitle'

import DifferentialsDesktop from './DifferentialsDesktop'
import DifferentialsMobile from './DifferentialsMobile'

import * as S from './styled'

const Differentials = ({ differentialsData }) => {
  const t = useTranslations()
  const translatedSectionTitle = t`Diferenciais`

  const screen = typeof window !== `undefined` ? window.screen : false
  const [mobileDevice, setMobileDevice] = useState(true)

  useEffect(() => {
    if (!isMobile(screen)) {
      setMobileDevice(false)
    }
  }, [screen])

  return (
    <S.DifferentialsContainer>
      <SectionTitle title={translatedSectionTitle} />
      {mobileDevice ? (
        <DifferentialsMobile differentialsData={differentialsData} />
      ) : (
        <DifferentialsDesktop differentialsData={differentialsData} />
      )}
    </S.DifferentialsContainer>
  )
}

Differentials.propTypes = {
  differentialsData: PropTypes.array.isRequired,
}

export default Differentials
